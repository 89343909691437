import {
  Box,
  Container,
  Typography,
  Link,
  List,
  ListItemIcon,
} from "@mui/material";
import {
  BuildCircle,
  ThumbUp,
  Park,
  People,
  EmojiEvents,
} from "@mui/icons-material";
import { SECTION_MARGIN_BOTTOM } from "../../constants";

const BulletPoints = () => {
  const listData = [
    {
      text: "Nasz zespół składa się z wykwalifikowanych specjalistów z wieloletnim doświadczeniem w branży",
      icon: <People />,
      label: "Doświadczenie i Profesjonalizm",
    },
    {
      text: "Korzystamy z najnowszych technologii i narzędzi, aby zagwarantować skuteczność i precyzję naszych usług",
      icon: <BuildCircle />,
      label: "Zaawansowane Technologie",
    },
    {
      text: "Używamy tylko sprawdzonych, wysokiej jakości środków czyszczących i farb, które zapewniają trwałość i estetykę",
      icon: <EmojiEvents />,
      label: "Najlepsze Materiały",
    },
    {
      text: "Naszym priorytetem jest pełna satysfakcja klienta. Indywidualne podejście i dbałość o każdy detal to nasze znaki rozpoznawcze",
      icon: <ThumbUp />,
      label: "Zadowolenie Klienta",
    },
    {
      text: "Dbamy o środowisko, stosując ekologiczne metody czyszczenia i malowania",
      icon: <Park />,
      label: "Ekologiczne Rozwiązania",
    },
  ];

  return (
    <List>
      {listData.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            p: 2,
            borderRadius: "1.25rem",
            backgroundColor: "#f0f4ff",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            position: "relative",
          }}
        >
          <ListItemIcon sx={{ minWidth: "2rem" }}>{item.icon}</ListItemIcon>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: "bold", fontSize: "0.875rem" }}
            >
              {item.label}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", fontSize: "0.75rem" }}
            >
              {item.text}
            </Typography>
          </Box>
        </Box>
      ))}
    </List>
  );
};

const SecondSection: React.FC = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: SECTION_MARGIN_BOTTOM,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#edf1fc",
          boxSizing: "border-box",
          borderRadius: "1.25rem",
          padding: "1rem",
          width: { xs: "100%", md: "80%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            mb: "0.5rem",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              flex: 1,
              textAlign: { xs: "center", md: "left" },
              fontWeight: "bold",
              ml: { md: "3rem" },
            }}
          >
            Lider w branży mycia i malowania elewacji, dachów oraz kostki
            brukowej, nagrodzony tytułem najlepszej firmy w Szczecinie w 2023 i
            2024 roku
          </Typography>
          <Link
            href="https://www.oferteo.pl/elewacje/szczecin"
            target="_blank"
            rel="noopener"
            sx={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <img
              src="https://www.oferteo.pl/images/buttons/badge-best-current-2024.svg"
              alt="Oferteo Badge"
              style={{
                width: "12rem",
                maxWidth: "100%",
              }}
            />
          </Link>
        </Container>

        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: "1rem",
          }}
        >
          <BulletPoints />
        </Container>
      </Box>
    </Container>
  );
};

export default SecondSection;
