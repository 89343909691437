import { Box, Container, Typography } from "@mui/material";
import logo from "../../assets/logo.jpg";
import CustomButton from "../custom-button/CustomButton";
import CustomDialog from "../custom-dialog/CustomDialog";
import ContactDetails from "./ContactDetails";
import { useState } from "react";

const FirstSection: React.FC = () => {
  const [openContactDetails, setOpenContactDetails] = useState(false);
  return (
    <Container
      maxWidth="md"
      sx={{
        minHeight: "100vh",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box pt={8} pb={6} width="100%">
        <img
          src={logo}
          alt="Logo"
          style={{
            width: "auto",
            height: "15rem",
            marginTop: "0.625rem",
            maxWidth: "100%",
          }}
        />
        <Typography
          variant="h6"
          color="textSecondary"
          paragraph
          sx={{
            fontSize: { xs: "1rem", sm: "1.125rem", md: "1.25rem" },
            overflowWrap: "break-word",
          }}
        >
          MK Renovation to profesjonalna firma specjalizująca się w
          kompleksowych usługach czyszczenia i renowacji zewnętrznych
          powierzchni budynków. Oferujemy skuteczne czyszczenie elewacji, dachów
          oraz kostki brukowej, zapewniając im długotrwały, estetyczny wygląd.
          Nasze usługi obejmują również malowanie elewacji, które nadaje
          budynkom świeży, nowoczesny wygląd i dodatkową ochronę przed warunkami
          atmosferycznymi. Dzięki zaawansowanym technologiom oraz doświadczonemu
          zespołowi, MK RENOVATION gwarantuje wysoką jakość usług,
          bezpieczeństwo oraz zadowolenie klientów. Zaufaj profesjonalistom i
          odśwież wygląd swojego domu z MK Renovation!
        </Typography>
        <CustomButton
          text="Poproś o wycenę"
          onClick={() => setOpenContactDetails(true)}
        />
      </Box>
      <CustomDialog
        open={openContactDetails}
        onClose={() => setOpenContactDetails(false)}
        title="Dane Kontaktowe"
        body={<ContactDetails />}
      />
    </Container>
  );
};

export default FirstSection;
