import React from "react";
import {
  Typography,
  Container,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import {
  WbSunny,
  Coronavirus,
  AutoAwesome,
  Opacity,
  BlurOn,
  WaterDamage,
  Palette,
} from "@mui/icons-material";
import { COLOR_PRIMARY, SECTION_MARGIN_BOTTOM } from "../../constants";

const FourthSection: React.FC = () => {
  const items = [
    {
      icon: <WbSunny fontSize="large" />,
      title: "Ochrona przed Czynnikami Zewnętrznymi",
      description:
        "Nowa powłoka farby tworzy barierę przed deszczem, promieniami UV, wiatrem i ekstremalnymi temperaturami, zapobiegając degradacji materiałów elewacyjnych",
    },
    {
      icon: <Coronavirus fontSize="large" />,
      title: "Zapobieganie Wzrostowi Glonów, Mchów i Porostów",
      description:
        "Malowanie elewacji specjalistycznymi farbami antygrzybicznymi i hydrofobowymi skutecznie chroni przed rozwojem glonów, mchów i porostów, które mogą niszczyć strukturę budynku",
    },
    {
      icon: <AutoAwesome fontSize="large" />,
      title: "Poprawa Estetyki",
      description:
        "Świeżo pomalowana elewacja odświeża wygląd budynku, dodając mu nowoczesności i zwiększając jego wartość rynkową",
    },
    {
      icon: <BlurOn fontSize="large" />,
      title: "Zielone i Czerniejące Plamy",
      description:
        "Glony, mchy i porosty pojawiające się na elewacji to nie tylko problem estetyczny, ale również sygnał, że wilgoć przenika do struktury, co może prowadzić do poważniejszych uszkodzeń",
    },
    {
      icon: <Opacity fontSize="large" />,
      title: "Łuszczenie i Odpryskiwanie Farby",
      description:
        "Kiedy farba zaczyna się łuszczyć lub odpryskiwać, elewacja traci swoją ochronę, narażając budynek na działanie niekorzystnych warunków atmosferycznych",
    },
    {
      icon: <Palette fontSize="large" />,
      title: "Blaknięcie Koloru",
      description:
        "Wyblakła farba traci swoje właściwości ochronne, co może prowadzić do szybszej degradacji elewacji",
    },
    {
      icon: <WaterDamage fontSize="large" />,
      title: "Zacieki i Plamy",
      description:
        "Widoczne zacieki i plamy mogą świadczyć o problemach z wilgocią, które wymagają natychmiastowej interwencji",
    },
  ];

  return (
    <Box sx={{ mb: SECTION_MARGIN_BOTTOM }}>
      <Container maxWidth="md">
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Dlaczego Warto Malować Elewacje?
        </Typography>

        <Grid container spacing={4} sx={{ mb: 6 }}>
          {items.slice(0, 3).map((item, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                  >
                    {item.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", textAlign: "center" }}
        >
          Objawy Wskazujące na Potrzebę Malowania Elewacji
        </Typography>

        <Grid container spacing={4} sx={{ mb: 6 }}>
          {items.slice(3).map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card
                sx={{
                  textAlign: "center",
                  backgroundColor: "#f5f5f5",
                  color: "#333",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mb: 2 }}
                  >
                    {item.icon}
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2">{item.description}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card
              sx={{
                textAlign: "center",
                backgroundColor: COLOR_PRIMARY,
                color: "white",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                <Typography variant="body1">
                  Malowanie elewacji to inwestycja, która przynosi długotrwałe
                  korzyści, chroniąc Twój budynek i zachowując jego piękny
                  wygląd. Zaufaj MK Renovation, aby zapewnić profesjonalne i
                  skuteczne malowanie elewacji, które zabezpieczy Twój dom przed
                  glonami, mchami i porostami, zapewniając mu trwałość i
                  estetykę na lata
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FourthSection;
