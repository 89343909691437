import React, { useRef } from "react";
import "./index.css";
import ServiceSection from "./components/third-section/ServiceSection";
import Footer from "./components/footer/Footer";
import SecondSection from "./components/second-section/SecondSection";
import FourthSection from "./components/fourth-section/FourthSection";
import FirstSection from "./components/first-section/FirstSection";
import NavigationBar from "./components/first-section/NavigationBar";

function App() {
  const secondSectionRef = useRef<HTMLDivElement>(null);
  const serviceSectionRef = useRef<HTMLDivElement>(null);
  const fourthSectionRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <NavigationBar
        scrollToSection={scrollToSection}
        refs={{
          second: secondSectionRef,
          service: serviceSectionRef,
          fourth: fourthSectionRef,
        }}
      />
      <FirstSection />
      <div ref={secondSectionRef}>
        <SecondSection />
      </div>
      <div ref={serviceSectionRef}>
        <ServiceSection />
      </div>
      <div ref={fourthSectionRef}>
        <FourthSection />
      </div>
      <Footer />
    </div>
  );
}

export default App;
