import { useEffect, useState } from "react";
import { Grid, Container, Typography, Divider } from "@mui/material";
import dachy from "../../assets/dachy.jpg";
import elewacje from "../../assets/elewacje.jpg";
import kostkaBrukowa from "../../assets/kostka-brukowa.jpg";
import AOS from "aos";
import Service, { ServiceInfo } from "./Service";
import ServiceDialog from "./ServiceDialog";
import { SECTION_MARGIN_BOTTOM } from "../../constants";

const ElewacjeBody = () => {
  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Mycie ciśnieniowe
      </Typography>
      <Typography variant="body1">
        Czy wiesz, że Twoja elewacja może wyglądać jak nowa bez konieczności
        malowania? Oferujemy profesjonalne mycie ciśnieniowe, które skutecznie
        usuwa zabrudzenia, pleśń i naloty, przywracając świeży wygląd Twojego
        budynku. Dzięki naszej zaawansowanej technologii i doświadczonemu
        zespołowi, Twoja elewacja odzyska blask, oszczędzając czas i pieniądze
        na malowanie. Wybierz MK Renovaion i ciesz się odświeżonym wyglądem
        swojego domu!
      </Typography>
      <Divider sx={{ m: 2 }} />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Malowanie
      </Typography>
      <Typography variant="body1">
        Odśwież wygląd swojego budynku dzięki profesjonalnym usługom malowania
        elewacji od MK Renovation. Oferujemy dwa precyzyjne sposoby malowania:
        <ul>
          <li>
            <strong>Tradycyjne Malowanie Wałkiem:</strong> Idealne do mniejszych
            powierzchni i detali, zapewnia równomierne krycie i doskonałe
            wykończenie.
          </li>
          <li>
            <strong>Malowanie Agregatem Malarskim:</strong> Szybkie i efektywne
            rozwiązanie dla dużych powierzchni, gwarantujące jednolitą i trwałą
            powłokę.
          </li>
        </ul>
        Nasz doświadczony zespół dobierze najlepszą metodę, aby zapewnić
        trwałość i estetykę Twojej elewacji. Wybierz MK Renovation i ciesz się
        pięknym, odnowionym wyglądem swojego budynku!
      </Typography>
      <Divider sx={{ m: 2 }} />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Impregnacja
      </Typography>
      <Typography variant="body1">
        Zabezpiecz swoją elewację na długie lata dzięki profesjonalnej
        impregnacji oferowanej przez MK Renovation. Nasze usługi impregnacyjne
        chronią powierzchnię przed wnikaniem wilgoci, zabrudzeniami i działaniem
        szkodliwych czynników atmosferycznych. Stosujemy najwyższej jakości
        środki, które nie tylko przedłużają żywotność elewacji, ale także
        podkreślają jej naturalne walory estetyczne. Nasz zespół ekspertów
        zapewnia precyzyjne i trwałe zabezpieczenie, dzięki któremu Twój budynek
        będzie wyglądał jak nowy przez wiele lat. Wybierz MK Renovation i
        zainwestuj w długotrwałą ochronę swojej elewacji!
      </Typography>
    </div>
  );
};

const DachyBody = () => {
  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Mycie
      </Typography>
      <Typography variant="body1">
        Czy wiesz, że regularne mycie dachu to klucz do zachowania jego
        trwałości i estetyki? Oferujemy profesjonalne mycie ciśnieniowe dachów z
        wykorzystaniem metod alpinistycznych, które skutecznie usuwają
        zabrudzenia i porosty, przywracając dachowi świeży wygląd. Nasz
        doświadczony zespół skutecznie dociera do najtrudniej dostępnych miejsc,
        zapewniając kompleksową czystość i odnowę dachu. Wybierz MK Renovation i
        ciesz się pięknym, odświeżonym dachem!
      </Typography>
      <Divider sx={{ m: 2 }} />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Malowanie
      </Typography>
      <Typography variant="body1">
        Malowanie dachu to nie tylko kwestia estetyki, ale także ochrony przed
        warunkami atmosferycznymi i przedłużenia jego trwałości. Oferujemy
        profesjonalne malowanie dachów, które zapewnia trwałą ochronę i
        estetykę. Stosujemy wysokiej jakości farby, które zapewniają równomierne
        krycie i trwałość powłoki, chroniąc dach przed promieniowaniem UV i
        wilgocią. Nasz doświadczony zespół zadba o każdy detal, aby Twój dach
        wyglądał jak nowy i zachował swoje walory przez długie lata. Wybierz MK
        Renovation i zainwestuj w długotrwałą ochronę swojego dachu!
      </Typography>
      <Divider sx={{ m: 2 }} />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Impregnacja
      </Typography>
      <Typography variant="body1">
        Zabezpiecz swój dach przed wilgocią, pleśnią i porostami dzięki
        profesjonalnej impregnacji oferowanej przez MK Renovation. Nasze usługi
        impregnacyjne chronią dach przed szkodliwym działaniem czynników
        atmosferycznych, zapewniając mu długotrwałą ochronę i estetykę.
        Stosujemy najwyższej jakości środki, które penetrują strukturę dachu,
        zabezpieczając go przed wnikaniem wilgoci i zabrudzeń. Wybierz MK
        Renovation i ciesz się pięknym, zadbany dachem przez wiele lat!
      </Typography>
    </div>
  );
};

const KostkaBrukowaBody = () => {
  return (
    <div>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Mycie
      </Typography>
      <Typography variant="body1">
        Przywróć swojej kostce brukowej pierwotny blask dzięki profesjonalnemu
        czyszczeniu ciśnieniowemu od MK Renovation! Nasza usługa skutecznie
        usuwa brud, mchy i plamy, zapewniając świeży i zadbany wygląd
        nawierzchni. Dodatkowo, oferujemy możliwość impregnacji po umyciu, co
        chroni kostkę przed zabrudzeniami i przedłuża jej trwałość. Wybierz MK
        Renovation i ciesz się piękną, czystą kostką brukową przez długie lata!
      </Typography>
      <Divider sx={{ m: 2 }} />
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        Impregnacja
      </Typography>
      <Typography variant="body1">
        Zabezpiecz swoją kostkę brukową na długie lata dzięki profesjonalnej
        impregnacji oferowanej przez MK Renovation. Nasze usługi impregnacyjne
        chronią nawierzchnię przed wnikaniem wilgoci, zabrudzeniami i działaniem
        szkodliwych czynników atmosferycznych. Stosujemy najwyższej jakości
        środki, które nie tylko przedłużają żywotność kostki brukowej, ale także
        podkreślają jej naturalne walory estetyczne. Nasz zespół ekspertów
        zapewnia precyzyjne i trwałe zabezpieczenie, dzięki któremu Twoja
        nawierzchnia będzie wyglądała jak nowa przez wiele lat. Wybierz MK
        Renovation i zainwestuj w długotrwałą ochronę swojej kostki brukowej!
      </Typography>
    </div>
  );
};

const services: ServiceInfo[] = [
  {
    id: 1,
    header: "Elewacje",
    subHeader: "Mycie ciśnieniowe • Malowanie • Impregnacja",
    dialogHeader: "Elewacje",
    body: <ElewacjeBody />,
    imageUrl: elewacje,
  },
  {
    id: 2,
    header: "Dachy",
    subHeader: "Mycie • Malowanie • Impregnacja",
    dialogHeader: "Dachy",
    body: <DachyBody />,
    imageUrl: dachy,
  },
  {
    id: 3,
    header: "Kostka Brukowa",
    subHeader: "Mycie • Impregnacja",
    dialogHeader: "Kostka Brukowa",
    body: <KostkaBrukowaBody />,
    imageUrl: kostkaBrukowa,
  },
];

const ServiceSection: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<ServiceInfo | null>(
    null
  );

  const handleClickOpen = (service: ServiceInfo) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setSelectedService(null), 300); // delay clearing the selected service
  };

  return (
    <Container sx={{ mb: SECTION_MARGIN_BOTTOM }}>
      <Grid container spacing={10} alignItems="center">
        {services.map((service) => (
          <Grid item xs={12} key={service.id} data-aos="fade-up">
            <Service
              service={service}
              onClick={() => handleClickOpen(service)}
            />
          </Grid>
        ))}
      </Grid>

      {selectedService && (
        <ServiceDialog
          open={open}
          onClose={handleClose}
          service={selectedService}
        />
      )}
    </Container>
  );
};

export default ServiceSection;
