import { Grid, Paper, Typography, Box } from "@mui/material";

export interface ServiceInfo {
  id: number;
  header: string;
  subHeader: string;
  dialogHeader: string;
  body: string | React.ReactNode;
  imageUrl: string;
}

interface ServiceProps {
  service: ServiceInfo;
  onClick?: () => void;
}

const Service: React.FC<ServiceProps> = ({ service, onClick }) => {
  const isImageLeft = service.id % 2 === 0;
  return (
    <Grid item xs={12} display="flex" justifyContent="center" onClick={onClick}>
      <Paper
        elevation={4}
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: isImageLeft ? "row" : "row-reverse",
          },
          borderRadius: "1.25rem",
          overflow: "hidden",
          width: "80%",
          cursor: "pointer",
          transition: "transform 0.3s",
          "&:hover": {
            transform: "scale(1.05)",
          },
        }}
      >
        <Box
          component="img"
          src={service.imageUrl}
          alt="Image not found"
          sx={{
            width: { xs: "100%", md: "50%" },
            height: { xs: "15.625rem", md: "auto" },
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "1rem", md: "2rem" },
            width: { xs: "100%", md: "50%" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {/* header */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              mb: { xs: "0.5rem", md: "1rem" },
            }}
          >
            {service.header}
          </Typography>

          {/* subHeader */}
          <Typography variant="body1" sx={{ mb: { xs: "1rem", md: "2rem" } }}>
            {service.subHeader.split(" / ").join(" • ")}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Service;
