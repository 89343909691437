import { Paper, List, ListItem, ListItemText } from "@mui/material";

const Pricing = () => {
  const pricingData = [
    { service: "Mycie ciśnieniowe elewacji", price: "od 12zł/m²" },
    { service: "Malowanie elewacji", price: "od 30zł/m²" },
    { service: "Mycie ciśnieniowe dachów", price: "od 14zł/m²" },
    { service: "Mycie ciśnieniowe kostki brukowej", price: "od 10zł/m²" },
    { service: "Impregnacja", price: "od 8zł/m²" },
    { service: "Remonty i inne prace wyceniane są indywidualnie", price: "" },
  ];

  return (
    <Paper
      elevation={3}
      sx={{ padding: 4, marginTop: 4, borderRadius: "1.25rem" }}
    >
      <List>
        {pricingData.map((item, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={item.service}
              secondary={item.price}
              primaryTypographyProps={{ variant: "body1" }}
              secondaryTypographyProps={{
                variant: "body2",
                color: "textSecondary",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default Pricing;
