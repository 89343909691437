import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  SxProps,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CustomDialog from "../custom-dialog/CustomDialog";
import { useState } from "react";
import Pricing from "./Pricing";
import ContactDetails from "./ContactDetails";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface NavigationBarProps {
  scrollToSection: (ref: React.RefObject<HTMLDivElement>) => void;
  refs: {
    second: React.RefObject<HTMLDivElement>;
    service: React.RefObject<HTMLDivElement>;
    fourth: React.RefObject<HTMLDivElement>;
  };
}

const TYPOGRAPHY_SX: SxProps = {
  cursor: "pointer",
  color: "grey",
  "&:hover": {
    color: "black",
  },
  fontSize: "1.1rem",
  fontWeight: "bold",
  transition: "color 0.3s",
};

const NavigationBar: React.FC<NavigationBarProps> = ({
  scrollToSection,
  refs,
}) => {
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [openPricing, setOpenPricing] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const barOptions = [
    {
      text: "O Nas",
      onClick: () => scrollToSection(refs.second),
    },
    {
      text: "Usługi",
      onClick: () => scrollToSection(refs.service),
    },
    {
      text: "Czy Warto",
      onClick: () => scrollToSection(refs.fourth),
    },
    {
      text: "Cennik",
      onClick: () => setOpenPricing(true),
    },
    {
      text: "Kontakt",
      onClick: () => setOpenContactDetails(true),
    },
  ];

  return (
    <AppBar
      position="absolute"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Container maxWidth="md">
        <Toolbar>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {!isSmallScreen && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexGrow: 1,
                }}
              >
                {barOptions.map((typography, index) => (
                  <Typography
                    key={index}
                    variant="h6"
                    sx={TYPOGRAPHY_SX}
                    onClick={typography.onClick}
                  >
                    {typography.text}
                  </Typography>
                ))}
              </Box>
            )}
            {isSmallScreen && (
              <Box
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  edge="end"
                  aria-label="menu"
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {barOptions.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              option.onClick();
              handleMenuClose();
            }}
          >
            {option.text}
          </MenuItem>
        ))}
      </Menu>
      <CustomDialog
        open={openContactDetails}
        onClose={() => setOpenContactDetails(false)}
        title="Dane Kontaktowe"
        body={<ContactDetails />}
      />
      <CustomDialog
        open={openPricing}
        onClose={() => setOpenPricing(false)}
        title="Cennik"
        body={<Pricing />}
      />
    </AppBar>
  );
};

export default NavigationBar;
