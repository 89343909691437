import { COLOR_PRIMARY } from "../../constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

interface CustomButtonProps {
  text: string;
  onClick: () => void;
}

const CustomButton: React.FC<CustomButtonProps> = ({ text, onClick }) => {
  const buttonTheme = createTheme({
    shape: {
      borderRadius: 10,
    },
    palette: {
      primary: {
        main: COLOR_PRIMARY,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        variant="contained"
        size="large"
        sx={{ mt: "1rem", fontSize: "1rem" }}
        onClick={onClick}
      >
        {text}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;
