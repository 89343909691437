import { Box, Typography } from "@mui/material";

const ContactDetails = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography variant="h6">Mateusz Karpinski</Typography>
      <Typography variant="body1">Email: email@mkrenovation.pl</Typography>
      <Typography variant="body1">Telefon: +48 534 139 117</Typography>
    </Box>
  );
};

export default ContactDetails;
