import React from "react";

import { ServiceInfo } from "./Service";
import CustomDialog from "../custom-dialog/CustomDialog";

interface ServiceDialogProps {
  open: boolean;
  onClose: () => void;
  service: ServiceInfo;
}

const ServiceDialog: React.FC<ServiceDialogProps> = ({
  open,
  onClose,
  service,
}) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title={service.dialogHeader}
      body={service.body}
    />
  );
};

export default ServiceDialog;
