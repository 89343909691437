import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  Divider,
  Container,
} from "@mui/material";
import { Instagram, Facebook } from "@mui/icons-material";
import logo from "../../assets/logo.jpg";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Container sx={{ mb: 10 }}>
      <Box
        component="footer"
        sx={{ py: 2, px: 2, mt: "auto", backgroundColor: "background.paper" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <img src={logo} alt="Logo" style={{ height: "80px" }} />
          <Stack direction="row" spacing={1}>
            <IconButton
              href="https://www.instagram.com/mk.renovation.zst/"
              color="inherit"
              target="_blank"
            >
              <Instagram />
            </IconButton>
            <IconButton
              href="https://www.facebook.com/profile.php?id=61553081214119"
              color="inherit"
              target="_blank"
            >
              <Facebook />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} /> {/* Horizontal line */}
        <Typography
          variant="caption"
          color="textSecondary"
          align="center"
          sx={{ display: "block" }}
        >
          © MK Renovation {currentYear}
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
