import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: string | React.ReactNode;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
  open,
  onClose,
  title,
  body,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        sx: { borderRadius: "1.25rem" },
      }}
      fullWidth
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <strong>{title}</strong>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {typeof body === "string" ? (
          <Typography variant="body1">{body}</Typography>
        ) : (
          body
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
